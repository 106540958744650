import React, {Component} from "react";
import * as _ from "lodash";
import {XUtils, XViewStatus} from "@michalrakus/x-react-web-lib/XUtils";
import {Panel} from "primereact/panel";
import {Checkbox} from "primereact/checkbox";
import {XEditFieldDialog} from "./XEditFieldDialog";
import {XButtonIconSmall} from "@michalrakus/x-react-web-lib/XButtonIconSmall";
import {InputText} from "primereact/inputtext";
import {intFromUI, numberFromModel, stringAsUI, stringFromUI} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {UtilsCommon} from "../../common/UtilsCommon";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {TabPanel, TabView} from "primereact/tabview";
import {Tooltip} from "primereact/tooltip";
import {XInputDecimalBase} from "@michalrakus/x-react-web-lib/XInputDecimalBase";
import {XUtilsMetadata} from "@michalrakus/x-react-web-lib/XUtilsMetadata";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

// zodpoveda entite XFieldSetMeta
export interface XFieldSetMeta {
    id?: number;
    fieldSetId: string; // cez toto id bude field set referencovany v kode
    //table: string;
    xFieldMetaRoot: XFieldMeta; // root field (neda sa zmazat)
    modifDate: Date | null;
    modifXUser: any | null;
    version: number;
}

export enum XFieldType {
    rootField = "rootField", // specialny root field
    fieldGroup = "fieldGroup", // obsahuje skupinku fieldov, nema DB stlpec (neperzistentny)
    fieldGroupWithCheckbox = "fieldGroupWithCheckbox", // obsahuje skupinku fieldov, skupinka sa zobrazi ak je checkbox zaskrtnuty
    checkbox = "checkbox", // klasicky dvojstavovy checkbox
    checkboxNullable = "checkboxNullable", // trojstavovy checkbox true/false/null
    inputText = "inputText",
    inputDecimal = "inputDecimal"
}

export const xFieldTypeOptionsToAdd: XFieldType[] = [XFieldType.fieldGroup, XFieldType.checkbox, XFieldType.inputText, XFieldType.inputDecimal];

// children layout sa da nastavit len na type, ktory ma children - rootField, fieldGroup
export enum XChildrenLayout {
    column = "column",  // default - vyrenderuje children do stlpca
    row = "row",        // vyrenderuje children do riadku
    tabView = "tabView", // ma zmysel, ak su children typu fieldGroup (labely tychto children fieldGroup-ov budu pouzite ako labely jednotlivych tab-iek)
    tabViewWithHeaderGroup = "tabViewWithHeaderGroup" // to iste co tabView ale prvy fieldGroup (0-ty tab) zobrazi nad tabView (aby bol vzdy pristupny)
}
// poznamka - tabView a tabViewWithHeaderGroup odignoruju bezne simple children - ak take budu existovat, zobrazi sa uzivatelovi warning pri nastaveni tabView

export const xChildrenLayoutOptionsToAdd: XChildrenLayout[] = [XChildrenLayout.column, XChildrenLayout.row, XChildrenLayout.tabView/*, XChildrenLayout.tabViewWithHeaderGroup*/];

// pouzijeme len checkbox supressFieldGroupPanel
// pouziva sa len pre typ fieldGroup a len v pripade ak dany fieldGroup nie je vyrenderovany v nejakej tab-ke (jeho parent ma nastaveny layout tabView*)
// export enum XFieldGroupPanelViewStatus {
//     show = "show",  // default - vyrenderuje Panel aj s labelom a vo vnutri panelu umiestni children
//     hide = "hide"   // nerenderuje Panel, children umiestni do div elementu (label sa nepouzije) - pouziva sa na vytvorenie stlpcov v (parent) fieldGroup-e
// }

export enum XFieldPropValuesFrom {
    parent = "parent",  // field prop values preberame z parenta
    this = "this"       // field prop values preberame z current fieldu
}

export interface XFieldMeta {
    type?: XFieldType;
    childrenLayout?: XChildrenLayout; // pouziva sa len na type, ktory ma children - rootField, fieldGroup (default hodnota je "column")
    width?: string; // hodnota napr. 10rem, pouziva sa hlavne ak childrenLayout = column
    labelWidth?: string; // hodnota napr. 6rem, pouziva sa (zatial) len pre type = inputText (default nastaveny cez css je 100% - sposobi odsunutie/zarovanie inputu doprava)
    inputWidth?: string; // hodnota napr. 4rem, pouziva sa (zatial) len pre type = inputText
    suppressFieldGroupPanel?: true; // pouziva sa len pre typ fieldGroup a len v pripade ak dany fieldGroup nie je vyrenderovany v nejakej tab-ke (jeho parent ma nastaveny layout tabView*)
                                    // ak true tak nerenderuje Panel, children umiestni do div elementu (label sa nepouzije) - pouziva sa na vytvorenie stlpcov v (parent) fieldGroup-e
    field: string; // pouziva sa v kode a zaroven je to nazov stlpca - napr. obciansky_preukaz
    label: string; // pouzije sa vo formulari ako label
    tooltip?: string; // pouzije sa vo formulari ako tooltip
    decimalProps?: {scale: number;}; // pouzivane pre inputDecimal (scale = pocet des. miest), mozno v buducnosti pridame precision (pocet cifier vcetne scale) a useGrouping
    //notNull: boolean; // default false, notNull mozu byt len tie stlpce ktore su vzdy vyplnene (bud maju vyplnenu defaultValue alebo su vzdy viditelne (nemaju vyplnene dateFrom/dateTo))
    //defaultValue: String; // toto je problem, musime to tu zapisat v UI formate!
    dateFrom?: Date;
    dateTo?: Date;
    xFieldMetaList?: XFieldMeta[];
    fieldPropValuesMapForEdit?: XFieldPropValuesMapForEdit; // ak pouzijeme parameter "fieldProps", tak sem sa ukladaju hodnoty vykliknute v XEditFieldDialog
    fieldPropValuesMapCached?: XFieldPropValuesMap; // to iste co fieldPropValuesMapForEdit ale je zohladneny aj pripadny valuesFrom === parent, neuklada sa do DB, vypocitava sa po nacitani z DB
    xFieldMetaParentCached?: XFieldMeta; // pomocny atribut aby sme si zjednodusili kod a nemuseli vsade pretlacat parameter s parentom, neuklada sa do DB, vypocitava sa po nacitani z DB
}

export interface XFieldPropValuesMapForEdit {
    [field: string]: XFieldPropValuesForEdit; // id-cko je XFieldProp.id
}

export interface XFieldPropValuesMap {
    [field: string]: XFieldPropValues; // id-cko je XFieldProp.id
}

export interface XFieldPropValuesForEdit {
    valuesFrom: XFieldPropValuesFrom; // hodnota dropdownu "Values from"
    values?: XFieldPropValues; // ak valuesFrom = "this" tak tu su vykliknute items, inac je tu undefined (bez atributu values)
    error?: string; // specialny atribut kam si odkladame chybu z autocomplete (iba pri editacii sa pouziva (po skonceni editacie je vzdy undefined))
}

// presny typ urcuje pouzity <Name>FieldPropEdit - moze tu byt boolean, number, object
export type XFieldPropValues = any | undefined;

// datova struktura na vytvorenie specifickych aplikacnych atributov zadavanych v XEditFieldDialog
// pole instancii tejto struktury prichadza ako parameter fieldProps do komponentu XFieldSet
export interface XFieldProp {
    id: string;
    label: string;
    fieldPropEdit?: JSX.Element; // fieldPropEdit je komponent na editaciu fieldProp, ma props typu XFieldPropEditProps
}

// specialny interface (api) pre custom komponent (nazyvany <Name>FieldPropEdit),
// vdaka tomuto interface sa v XEditFieldDialog zobrazi/zaintegruje custom component,
// ktory sluzi na editaciu custom property (tato property moze mat lubovolnu strukturu (object literal))
// (funguje to podobne ako napr. property searchBrowse v XAutoComplete - do property searchBrowse sa zapise browse component
// a XAutoComplete pouziva tento browse component)
export interface XFieldPropEditProps {
    value?: XFieldPropValues;
    onChange?: (value: XFieldPropValues) => void;
    onErrorChange?: (error: (string | undefined)) => void;
}

// datova struktura do ktorej si zapisujeme hodnoty fieldov (struktura sa zapisuje do DB do stlpca <jsonField>)
export interface XFieldSetValues {
    [field: string]: any;
}

// map sluziaci na rychle vyhladavanie XFieldMeta (hlavne pri renderovani klikaciek v browse)
// pouzivany v lib-ke v XLazyDataTable
export type XFieldXFieldMetaMap = Map<string, XFieldMeta>;

// struktura, ktoru vracia metoda fieldElemProps - nastavenim tychto props sa daju prebit default hodnoty pri renderovani elementov,
// cize pomocou metody fieldElemProps sa da ofarbickovat niektory checkbox, pripadne zmenit jeho label
// ak niektoru prop ponechame undefined, tak sa pouzije default hodnota (napr. label z XFieldMeta)
// ak bude treba zmenit nejaku inu prop pri renderovani, staci ju sem pridat
export interface XFieldElemProps {
    label?: string;
    className?: string;
}

export enum XFieldOperation {
    AddFieldAsChild,
    AddFieldAsSibling,
    EditField
}

// helper
interface XElemIdsAndTooltipElem {
    labelElemId: string;
    inputElemId: string;
    tooltipElem: JSX.Element;
}

export interface XFieldSetBaseProps {
    values: XFieldSetValues;
    onFieldChange: (field: string, value: any) => void; // odovzda vybraty objekt, ak bol vybraty objekt zmeneny cez dialog (aj v DB), tak vrati objectChange !== OperationType.None
    fieldSetId: string;
    fieldProps?: XFieldProp[];
    fieldViewStatus?: (xFieldMeta: XFieldMeta) => XViewStatus;
    fieldElemProps?: (xFieldMeta: XFieldMeta) => XFieldElemProps | undefined; // metoda ktorou sa da ovplyvnit rendering elementov - pozri XFieldElemProps
    canEdit?: boolean; // user moze field set editovat, len ak canEdit = true
    onEditFieldChange?: (xFieldMeta: XFieldMeta) => void; // zavola sa ked uzivatel pri editacii potvrdi editovaci dialog pre nejaky field
    // - pouziva sa ak nejaka property fieldu odkazuje na nejaky zoznam (napr. zoznam prav) a tento zoznam treba refreshnut, lebo bol pri editacii fieldu zmeneny
    onEditSave?: () => void; // ten isty dovod ako pri onEditFieldChange, ale niekedy napr. pre zmene viewStatus-u nam staci refresh az po ulozeni formulara (usetrime select)
}

export const xFieldMetaRootInit: XFieldMeta = {
    type: XFieldType.rootField,
    field: "root_field",
    label: "Root field",
    xFieldMetaList: []
};

export class XFieldSetBase extends Component<XFieldSetBaseProps> {

    // used as default in property "field" for fields of type fieldGroup
    static fieldGroupPrefix: string = "field_group_";

    state: {
        xFieldSetMeta?: XFieldSetMeta;
        //values: XFieldSetValues;
        editMode?: boolean; // editMode === undefined is used if editation is not allowed (e.g. user is not admin)
        editFieldDialogOpened: boolean;
    };

    // members used by editing (editMode = true)
    xFieldSetMetaOriginal?: XFieldSetMeta;
    xFieldOperationEnum?: XFieldOperation;
    xFieldMetaClicked?: XFieldMeta;
    xFieldMetaSiblingList?: XFieldMeta[];
    xEditFieldDialogParamXFieldMeta?: XFieldMeta;

    // members for special operation moveField - here is saved which field will be moved in next step (clipboard for field)
    siblingListSelected?: XFieldMeta[] = undefined;
    xFieldMetaSelected?: XFieldMeta = undefined;

    constructor(props: XFieldSetBaseProps) {
        super(props);

        this.state = {
            //values: {}
            editMode: false,
            editFieldDialogOpened: false
        };

        this.editFieldDialogOnHide = this.editFieldDialogOnHide.bind(this);
        this.getFieldByName = this.getFieldByName.bind(this);
        this.getMaxFieldGroupId = this.getMaxFieldGroupId.bind(this);
    }

    async componentDidMount() {
        let xFieldSetMeta: XFieldSetMeta = await this.readXFieldSetMeta();
        // TODO - nacitaj hodnoty z tabulky xFieldSetMeta.table, ak sa raz budu citat z tabulky
        this.setState({xFieldSetMeta: xFieldSetMeta});
    }

    private async readXFieldSetMeta(): Promise<XFieldSetMeta> {
        let xFieldSetMeta: XFieldSetMeta;
        const xFieldSetMetaList: XFieldSetMeta[] = await XUtils.fetchRows('XFieldSetMeta', {where: "[fieldSetId] = :fieldSetId", params: {fieldSetId: this.props.fieldSetId}});
        if (xFieldSetMetaList.length > 0) {
            xFieldSetMeta = xFieldSetMetaList[0];
        }
        else {
            // este nemame v db zaznam s metadatami - vytvorime zaznam s root fieldom
            xFieldSetMeta = {fieldSetId: this.props.fieldSetId, xFieldMetaRoot: xFieldMetaRootInit, modifDate: null, modifXUser: null, version: 0};
            //xFieldSetMeta = {fieldSetId: this.props.fieldSetId, xFieldMetaList: xFieldMetaListMock, modifDate: null, modifXUser: null, version: 0};
        }
        this.computeCachedFields(xFieldSetMeta.xFieldMetaRoot, undefined);
        return xFieldSetMeta;
    }

    private computeCachedFields(xFieldMeta: XFieldMeta, xFieldMetaParent: XFieldMeta | undefined) {
        xFieldMeta.xFieldMetaParentCached = xFieldMetaParent;
        xFieldMeta.fieldPropValuesMapCached = this.createXFieldPropValues(xFieldMeta.fieldPropValuesMapForEdit, xFieldMetaParent?.fieldPropValuesMapCached);
        if (xFieldMeta.xFieldMetaList) {
            for (const insideXFieldMeta of xFieldMeta.xFieldMetaList) {
                this.computeCachedFields(insideXFieldMeta, xFieldMeta);
            }
        }
    }

    // ************************* helper methods ******************************

    static isFieldGroup(xFieldType: XFieldType | undefined): boolean {
        return xFieldType === XFieldType.rootField || xFieldType === XFieldType.fieldGroup || xFieldType === XFieldType.fieldGroupWithCheckbox;
    }

    // *************** methods used for editing field set meta ***************

    private async onEditModeStart() {
        // nacitame aktualny stav z DB, nech mame najnovsiu verziu (obmedzime pripadny problem s optimistickym zamykanim)
        let xFieldSetMeta: XFieldSetMeta = await this.readXFieldSetMeta();
        // ulozime si povodny stav aby sme sa v pripade cancel editu vedeli k nemu vratit
        this.xFieldSetMetaOriginal = _.cloneDeep(xFieldSetMeta);
        this.setState({editMode: true, xFieldSetMeta: xFieldSetMeta});
    }

    private async onEditModeSave() {
        if (!window.confirm(xLocaleOption('fieldSetSaveEditConfirm'))) {
            return;
        }

        const xFieldSetMeta: XFieldSetMeta = this.state.xFieldSetMeta!;
        // clear cached computed data (not to be saved in DB)
        // POZNAMKA - ak by sme cached fields ukladali do DB, asi by sa nic nestalo, len by metadata zaberali viac miesta v DB (a boli by menej citatelne)
        // ale parent atribut by sme aj tak museli mazat
        this.clearCachedFields(xFieldSetMeta.xFieldMetaRoot);

        // add modif data
        xFieldSetMeta.modifDate = new Date();
        xFieldSetMeta.modifXUser = XUtils.getXToken()?.xUser;
        try {
            await XUtils.fetch('saveRow', {entity: 'XFieldSetMeta', object: xFieldSetMeta, reload: false});
        }
        catch (e) {
            XUtils.showErrorMessage("Save row failed.", e);
            return; // zostavame v edit mode
        }

        // kedze sme pred save zrusili cached fields, treba ich nanovo vyratat
        this.computeCachedFields(xFieldSetMeta.xFieldMetaRoot, undefined);

        // TODO - ulozit zmeny do pripadnej cache
        this.setState({editMode: false});

        if (this.props.onEditSave) {
            this.props.onEditSave();
        }
    }

    private clearCachedFields(xFieldMeta: XFieldMeta) {
        xFieldMeta.xFieldMetaParentCached = undefined;
        xFieldMeta.fieldPropValuesMapCached = undefined;
        if (xFieldMeta.xFieldMetaList) {
            for (const insideXFieldMeta of xFieldMeta.xFieldMetaList) {
                this.clearCachedFields(insideXFieldMeta);
            }
        }
    }

    private onEditModeCancel() {
        if (window.confirm(xLocaleOption('fieldSetCancelEditConfirm'))) {
            // vratime povodny formular
            this.setState({editMode: false, xFieldSetMeta: this.xFieldSetMetaOriginal});
        }
    }

    private onAddFieldAsChild(xFieldMetaParent: XFieldMeta) {

        // ak mame vyselektovany field, tak presuvame vyselektovany field
        if (this.xFieldMetaSelected) {
            UtilsCommon.arrayRemoveItem(this.siblingListSelected!, this.xFieldMetaSelected);
            xFieldMetaParent.xFieldMetaList!.push(this.xFieldMetaSelected); // prida na koniec
            // odselectujeme
            this.siblingListSelected = undefined;
            this.xFieldMetaSelected = undefined;
            this.setState({xFieldSetMeta: this.state.xFieldSetMeta});
        }
        else {
            this.xFieldOperationEnum = XFieldOperation.AddFieldAsChild;
            this.xFieldMetaClicked = xFieldMetaParent;
            this.xEditFieldDialogParamXFieldMeta = {field: "", label: "", xFieldMetaParentCached: xFieldMetaParent};
            this.setState({editFieldDialogOpened: true});
        }
    }

    private onAddFieldAsSibling(xFieldMetaSiblingList: XFieldMeta[], xFieldMetaUpperSibling: XFieldMeta) {

        // ak mame vyselektovany field, tak presuvame vyselektovany field
        if (this.xFieldMetaSelected) {
            UtilsCommon.arrayRemoveItem(this.siblingListSelected!, this.xFieldMetaSelected);
            const itemIndex = UtilsCommon.arrayIndexOf(xFieldMetaSiblingList, xFieldMetaUpperSibling);
            xFieldMetaSiblingList.splice(itemIndex + 1, 0, this.xFieldMetaSelected);
            // odselectujeme
            this.siblingListSelected = undefined;
            this.xFieldMetaSelected = undefined;
            this.setState({xFieldSetMeta: this.state.xFieldSetMeta});
        }
        else {
            this.xFieldOperationEnum = XFieldOperation.AddFieldAsSibling;
            this.xFieldMetaClicked = xFieldMetaUpperSibling;
            this.xFieldMetaSiblingList = xFieldMetaSiblingList;
            this.xEditFieldDialogParamXFieldMeta = {
                field: "",
                label: "",
                xFieldMetaParentCached: xFieldMetaUpperSibling.xFieldMetaParentCached
            };
            this.setState({editFieldDialogOpened: true});
        }
    }

    private onEditField(xFieldMetaSiblingList: XFieldMeta[] | undefined, xFieldMeta: XFieldMeta) {

        this.xFieldOperationEnum = XFieldOperation.EditField;
        this.xFieldMetaClicked = xFieldMeta;
        this.xFieldMetaSiblingList = xFieldMetaSiblingList;
        this.xEditFieldDialogParamXFieldMeta = xFieldMeta;
        this.setState({editFieldDialogOpened: true});
    }

    editFieldDialogOnHide(xFieldMeta: XFieldMeta | null) {
        if (xFieldMeta !== null) {
            if (this.xFieldOperationEnum === XFieldOperation.AddFieldAsChild) {
                //this.xFieldMetaClicked!.xFieldMetaList!.splice(0, 0, xFieldMeta); // prida na zaciatok
                this.xFieldMetaClicked!.xFieldMetaList!.push(xFieldMeta); // prida na koniec
            }
            else if (this.xFieldOperationEnum === XFieldOperation.AddFieldAsSibling) {
                const itemIndex = UtilsCommon.arrayIndexOf(this.xFieldMetaSiblingList!, this.xFieldMetaClicked);
                this.xFieldMetaSiblingList!.splice(itemIndex + 1, 0, xFieldMeta);
            }
            else if (this.xFieldOperationEnum === XFieldOperation.EditField) {
                if (xFieldMeta.type === XFieldType.rootField) {
                    this.state.xFieldSetMeta!.xFieldMetaRoot = xFieldMeta;
                }
                else {
                    const itemIndex = UtilsCommon.arrayIndexOf(this.xFieldMetaSiblingList!, this.xFieldMetaClicked);
                    this.xFieldMetaSiblingList![itemIndex] = xFieldMeta;
                }
            }
            // mohli sa zmenit fieldProps hodnoty, co mohlo ovplyvnit cely podstrom fieldov, takze preratame cely podstrom
            this.computeCachedFields(xFieldMeta, xFieldMeta.xFieldMetaParentCached);
            this.setState({xFieldSetMeta: this.state.xFieldSetMeta, editFieldDialogOpened: false});
            if (this.props.onEditFieldChange) {
                this.props.onEditFieldChange(xFieldMeta);
            }
            return;
        }
        this.setState({editFieldDialogOpened: false});
    }

    private onRemoveField(xFieldMetaSiblingList: XFieldMeta[], xFieldMeta: XFieldMeta) {
        if (window.confirm(xLocaleOption('fieldSetRemoveFieldConfirm'))) {
            UtilsCommon.arrayRemoveItem(xFieldMetaSiblingList, xFieldMeta);
            this.setState({xFieldSetMeta: this.state.xFieldSetMeta});
        }
    }

    private onMoveFieldUp(xFieldMetaSiblingList: XFieldMeta[], xFieldMeta: XFieldMeta) {
        this.moveFieldInArray(xFieldMetaSiblingList, xFieldMeta, -1);
    }

    private onMoveFieldDown(xFieldMetaSiblingList: XFieldMeta[], xFieldMeta: XFieldMeta) {
        this.moveFieldInArray(xFieldMetaSiblingList, xFieldMeta, 1);
    }

    private moveFieldInArray(xFieldMetaSiblingList: XFieldMeta[], xFieldMeta: XFieldMeta, offset: -1 | 1) {
        const itemIndex = UtilsCommon.arrayIndexOf(xFieldMetaSiblingList, xFieldMeta);
        XUtilsCommon.arrayMoveElement(xFieldMetaSiblingList, itemIndex, offset);
        this.setState({xFieldSetMeta: this.state.xFieldSetMeta});
    }

    private onSelectFieldForMove(xFieldMetaSiblingList: XFieldMeta[], xFieldMeta: XFieldMeta) {
        if (xFieldMeta === this.xFieldMetaSelected) {
            // zrusime vyselektovanie
            this.siblingListSelected = undefined;
            this.xFieldMetaSelected = undefined;
        }
        else {
            // user klikol na iny field
            this.siblingListSelected = xFieldMetaSiblingList;
            this.xFieldMetaSelected = xFieldMeta;
        }
        // hodnota this.xFieldMetaSelected ovplyvnuje vykreslovanie edit buttonov
        this.setState({xFieldSetMeta: this.state.xFieldSetMeta});
    }

    // ***************************** API *********************************

    getFieldByName(field: string): XFieldMeta | undefined {
        return this.getFieldByNameForXFieldMeta(this.state.xFieldSetMeta!.xFieldMetaRoot, field);
    }

    private getFieldByNameForXFieldMeta(xFieldMeta: XFieldMeta, field: string): XFieldMeta | undefined {
        if (xFieldMeta.field === field) {
            return xFieldMeta;
        }
        if (xFieldMeta.xFieldMetaList) {
            for (const insideXFieldMeta of xFieldMeta.xFieldMetaList) {
                const xFieldMetaFound: XFieldMeta | undefined = this.getFieldByNameForXFieldMeta(insideXFieldMeta, field);
                if (xFieldMetaFound) {
                    return xFieldMetaFound;
                }
            }
        }
        return undefined;
    }

    // helper na zistenie max id-cka (posledneho) vo field nazvoch typu field_group_143
    getMaxFieldGroupId(): number {
        return this.getMaxFieldGroupIdForXFieldMeta(this.state.xFieldSetMeta!.xFieldMetaRoot);
    }

    private getMaxFieldGroupIdForXFieldMeta(xFieldMeta: XFieldMeta): number {
        // pre istotu prejdeme vsetky fieldy, nielen tie typu groupField
        let maxId: number = 0;
        if (xFieldMeta.field.startsWith(XFieldSetBase.fieldGroupPrefix)) {
            const idString: string = xFieldMeta.field.substring(XFieldSetBase.fieldGroupPrefix.length);
            const id: number | null | undefined = intFromUI(idString);
            if (id && id > maxId) {
                maxId = id;
            }
        }
        if (xFieldMeta.xFieldMetaList) {
            for (const insideXFieldMeta of xFieldMeta.xFieldMetaList) {
                const xFieldMaxId: number = this.getMaxFieldGroupIdForXFieldMeta(insideXFieldMeta);
                if (xFieldMaxId > maxId) {
                    maxId = xFieldMaxId;
                }
            }
        }
        return maxId;
    }

    // ********************* API pre fieldProps ***************************

    /**
     * metoda vracia hodnotu field prop pre dany xFieldMeta
     * pouziva sa v handleroch fieldViewStatus a fieldClassName, ktore posktyju xFieldMeta ako parameter
     * ma zmysel len pre standardne field prop (nezafunguje pre custom field prop)
     * ak by bol xFieldMeta class (nie obycajny object literal), tak by toto bola member metoda
     *
     * @param xFieldMeta
     * @param fieldPropId
     * @param fieldPropItemId
     */
    // static getFieldPropValue(xFieldMeta: XFieldMeta, fieldPropId: string, fieldPropItemId: string): boolean {
    //     let value: boolean = false;
    //     const fieldPropValues: XFieldPropValues | undefined = XFieldSetBase.getFieldPropValues(xFieldMeta, fieldPropId);
    //     if (fieldPropValues) {
    //         const fieldPropItemValues: XFieldPropItemValues = fieldPropValues as XFieldPropItemValues;
    //         value = fieldPropItemValues[fieldPropItemId] ?? false;
    //     }
    //     return value;
    // }

    /**
     * metoda vracia hodnoty field prop pre dany xFieldMeta
     * pouziva sa v handleroch fieldViewStatus a fieldClassName, ktore posktyju xFieldMeta ako parameter
     * moze sa pouzit aj pre custom field prop
     * ak by bol xFieldMeta class (nie obycajny object literal), tak by toto bola member metoda
     *
     * ak je fieldProp na danom atribute pouzity, tak metoda vracia nejaku hodnotu/hodnoty - to co je vyplnene na danom field-e xFieldMeta
     * napr. pri XSimpleFieldPropEdit je fieldProp zaskrtnuty (mame hodnotu true), pri HistoriaFieldPropEdit ma fieldProp vyplneny pocet dni (mame number hodnotu) a pod.
     * ak fieldProp nie je na danom atribute pouzity, tak metoda vracia undefined
     *
     * POZNAMKA: fieldProp je nepouzity (metoda vracia undefined) ak je napr. XSimpleFieldPropEdit nezaskrtnuty a napr. HistoriaFieldPropEdit je nevyplneny
     * korektnejsie (a hlavne intuitivnejsie) by bolo mat specialny checkbox ktory ked sa zaskrtne tak az vtedy sa objavi input pre fieldProp value (napr. input na pocet dni pre HistoriaFieldPropEdit)
     * specialny checkbox by sa nepouzival pre XSimpleFieldPropEdit
     * input by bol povinny, ked je zaskrtnuty specialny checkbox
     *
     * @param xFieldMeta
     * @param fieldPropId
     */
    static getFieldPropValues(xFieldMeta: XFieldMeta, fieldPropId: string): XFieldPropValues | undefined {
        // kontrola
        if (!xFieldMeta.fieldPropValuesMapCached) {
            throw `Unexpected error: xFieldMeta.fieldPropValuesMapCached is undefined for xFieldMeta.field = ${xFieldMeta.field}`;
        }
        return xFieldMeta.fieldPropValuesMapCached[fieldPropId];
    }

    /**
     * pouziva sa vynimocne - napr. ak chceme koli performance nahradit id-cko referencovanym objektom
     *
     * @param xFieldMeta
     * @param fieldPropId
     * @param fieldPropValues
     */
    static setFieldPropValues(xFieldMeta: XFieldMeta, fieldPropId: string, fieldPropValues: XFieldPropValues | undefined) {
        // kontrola
        if (!xFieldMeta.fieldPropValuesMapCached) {
            throw `Unexpected error: xFieldMeta.fieldPropValuesMapCached is undefined for xFieldMeta.field = ${xFieldMeta.field}`;
        }
        xFieldMeta.fieldPropValuesMapCached[fieldPropId] = fieldPropValues;
    }

    /**
     * vrati zoznam fieldov, na ktorych mame pouzity dany fieldProp, t.j. fieldProp ma nejaku hodnotu (odlisnu od undefined),
     * napr. pri XSimpleFieldPropEdit je fieldProp zaskrtnuty (mame hodnotu true), pri HistoriaFieldPropEdit ma fieldProp vyplneny pocet dni (mame number hodnotu) a pod.
     *
     * POZNAMKA: asi by bolo efektivnejsie rovno vracat dvojicky (XFieldMeta, fieldPropValues), teda okrem XSimpleFieldPropEdit
     * aj ked z XFieldMeta vieme velmi rychlo ziskat fieldPropValues
     *
     * @param fieldPropId
     * @param xViewStatusFilterFrom - ak zadany, tak sa do zoznamu pridaju len fieldy s view statusom "xViewStatusFilterFrom" alebo vyssim
     *                              - xViewStatusFilterFrom = XViewStatus.ReadOnly filtruje fieldy s view statusom ReadOnly alebo ReadWrite
     *                              - xViewStatusFilterFrom = XViewStatus.ReadWrite filtruje fieldy s view statusom ReadWrite
     */
    getFieldListForFieldProp(fieldPropId: string, xViewStatusFilterFrom?: XViewStatus.ReadOnly | XViewStatus.ReadWrite): XFieldMeta[] {
        const fieldList: XFieldMeta[] = [];
        const xFieldSetMeta: XFieldSetMeta = this.state.xFieldSetMeta!;
        this.addFieldsForFieldProp(xFieldSetMeta.xFieldMetaRoot, fieldPropId, xViewStatusFilterFrom, fieldList);
        return fieldList;
    }

    /**
     * vrati true ak je zaskrtnuty nejaky checkbox (alebo vyplneny napr. inputtext), ktory je oznaceny danym fieldProp
     *
     * @param fieldPropId
     * @param xViewStatusFilterFrom - ak zadany, tak sa kontroluju len checkboxy s view statusom "xViewStatusFilterFrom" alebo vyssim
     */
    hasFieldOfFieldPropSomeValue(fieldPropId: string, xViewStatusFilterFrom?: XViewStatus.ReadOnly | XViewStatus.ReadWrite): boolean {
        const xFieldMetaList: XFieldMeta[] = this.getFieldListForFieldProp(fieldPropId, xViewStatusFilterFrom);
        for (const xFieldMeta of xFieldMetaList) {
            if (this.getFieldValue(xFieldMeta.field)) {
                return true;
            }
        }
        return false;
    }

    // ******************* support pre fieldProps **************************

    createXFieldPropValues(fieldPropValuesMapForEdit: XFieldPropValuesMapForEdit | undefined, fieldPropValuesMapParent: XFieldPropValuesMap | undefined): XFieldPropValuesMap | undefined {
        if (!this.props.fieldProps) {
            return undefined;
        }
        const xFieldPropValuesMap: XFieldPropValuesMap = {};
        for (const xFieldProp of this.props.fieldProps) {
            let xFieldPropValues: XFieldPropValues | undefined = undefined;
            if (fieldPropValuesMapForEdit) {
                const xFieldPropItemValuesForEdit: XFieldPropValuesForEdit = fieldPropValuesMapForEdit[xFieldProp.id];
                if (xFieldPropItemValuesForEdit) {
                    if (xFieldPropItemValuesForEdit.valuesFrom === XFieldPropValuesFrom.this) {
                        xFieldPropValues = xFieldPropItemValuesForEdit.values;
                    }
                }
            }
            // default pre vsetky ine pripady ako valuesFrom === this su itemValues z parenta
            if (xFieldPropValues === undefined) {
                if (fieldPropValuesMapParent) {
                    xFieldPropValues = fieldPropValuesMapParent[xFieldProp.id];
                }
            }
            if (xFieldPropValues) {
                xFieldPropValuesMap[xFieldProp.id] = xFieldPropValues;
            }
        }
        return xFieldPropValuesMap;
    }

    addFieldsForFieldProp(xFieldMeta: XFieldMeta, fieldPropId: string, xViewStatusFilterFrom: XViewStatus.ReadOnly | XViewStatus.ReadWrite | undefined, fieldList: XFieldMeta[]) {
        if (xViewStatusFilterFrom) {
            // zohladnime view status filter
            const fieldViewStatus: XViewStatus = this.getFieldViewStatus(xFieldMeta);
            if (xViewStatusFilterFrom === XViewStatus.ReadOnly) {
                if (fieldViewStatus !== XViewStatus.ReadOnly && fieldViewStatus !== XViewStatus.ReadWrite) {
                    return;
                }
            }
            else if (xViewStatusFilterFrom === XViewStatus.ReadWrite) {
                if (fieldViewStatus !== XViewStatus.ReadWrite) {
                    return;
                }
            }
        }

        const fieldPropValues: any | undefined = XFieldSetBase.getFieldPropValues(xFieldMeta, fieldPropId);
        if (fieldPropValues !== undefined) {
            fieldList.push(xFieldMeta);
        }
        if (xFieldMeta.xFieldMetaList) {
            for (const insideXFieldMeta of xFieldMeta.xFieldMetaList) {
                this.addFieldsForFieldProp(insideXFieldMeta, fieldPropId, xViewStatusFilterFrom, fieldList);
            }
        }
    }

    // *************** methods used for rendering field set *******************

    getFieldValue(field: string): any {
        return this.props.values[field];
    }

    // ******** inputText - metody prebrate z XInputText ******

    inputTextGetValue(field: string): string {
        // konvertovat null hodnotu na "" (vo funkcii stringAsUI) je dolezite aby sa prejavila zmena na null v modeli
        const value: string | null = this.getFieldValue(field) ?? null;
        return stringAsUI(value);
    }

    inputTextOnValueChange(field: string, e: any) {
        const value: string | null = stringFromUI(e.target.value);
        this.props.onFieldChange(field, value ?? undefined); // null value prehodime na undefined -> nevznikne json atribut v DB (space retazce napr. "  " sa zapisuju)
    }

    // ******** inputDecimal - metody prebrate z XLazyDataTable ******

    inputDecimalGetValue(field: string): number | null {
        // pripadny undefined prehodime na null
        // ak mame string (nacitali sme json z db), prehodime na number
        const value: number | null = numberFromModel(this.getFieldValue(field) ?? null);
        return value;
    }

    inputDecimalOnValueChange(field: string, value: number | null) {
        this.props.onFieldChange(field, value ?? undefined); // null value prehodime na undefined -> nevznikne json atribut v DB
    }

    createXFieldRoot(xFieldMetaRoot: XFieldMeta): JSX.Element {

        // like for other fields, if view status is hidden, no field and also no its children are rendered
        const fieldViewStatus: XViewStatus = this.getFieldViewStatus(xFieldMetaRoot);

        let insideElements: JSX.Element[];
        if (fieldViewStatus === XViewStatus.ReadWrite || fieldViewStatus === XViewStatus.ReadOnly) {
            // first create inside elements
            insideElements = this.createInsideElements(xFieldMetaRoot, false);
            insideElements = this.applyChildrenLayout(xFieldMetaRoot, insideElements);
        }
        else {
            // zatial dame upozornenie - asi nikto nebude chciet pouzivat viewStatus hidden pre root field
            insideElements = [<div>Warning: Root field has view status Hidden</div>];
        }

        // dame div navyse aby sme vedeli nastavit pripadnu width
        // div uklada child elementy pod seba (standardny display:block), zarovnane su dolava
        const styleWidth: React.CSSProperties | undefined = xFieldMetaRoot.width ? {width: xFieldMetaRoot.width} : undefined;
        const elem: JSX.Element =
            <div style={styleWidth}>
                {this.createEditElemRoot(xFieldMetaRoot)}
                {insideElements}
            </div>;
        return elem;
    }

    createEditElemRoot(xFieldMetaRoot: XFieldMeta): JSX.Element {
        let editElem = <div key="div-edit-elem-root"/>;
        if (this.state.editMode === true) {
            editElem = <div key="div-edit-elem-root" className="flex">
                <XButtonIconSmall key="button-save" icon="pi pi-save" onClick={() => this.onEditModeSave()} tooltip="Save field set"/>
                <XButtonIconSmall key="button-cancel" icon="pi pi-times" onClick={() => this.onEditModeCancel()} tooltip="Cancel editing"/>
                <div key="div-separator" style={{width:'3rem'}}/>
                <XButtonIconSmall key="button-add-child" icon="pi pi-plus" onClick={() => this.onAddFieldAsChild(xFieldMetaRoot)} tooltip="Add child field"/>
                <XButtonIconSmall key="button-edit-root" icon="pi pi-pencil" onClick={() => this.onEditField(undefined, xFieldMetaRoot)} tooltip="Edit root field"/>
            </div>;
        }
        else if (this.state.editMode === false && this.props.canEdit) {
            editElem = <div key="div-edit-elem-root">
                <XButtonIconSmall icon="pi pi-pencil" onClick={() => this.onEditModeStart()} tooltip="Edit field set"/>
            </div>;
        }
        // else - canEdit is not true - fieldSet is not editable
        return editElem;
    }

    createXField(xFieldMetaSiblingList: XFieldMeta[], xFieldMeta: XFieldMeta, createTabPanel: boolean, parentFieldSelected: boolean): JSX.Element | undefined {
        let xFieldElem: JSX.Element | undefined = undefined;

        const fieldViewStatus: XViewStatus = this.getFieldViewStatus(xFieldMeta);

        if (fieldViewStatus === XViewStatus.ReadWrite || fieldViewStatus === XViewStatus.ReadOnly) {
            let fieldClassName: string = "";
            let xFieldElemProps: XFieldElemProps | undefined = undefined;
            if (this.props.fieldElemProps) {
                xFieldElemProps = this.props.fieldElemProps(xFieldMeta);
                if (xFieldElemProps && xFieldElemProps.className) {
                    fieldClassName = xFieldElemProps.className;
                }
            }

            if (xFieldMeta === this.xFieldMetaSelected || parentFieldSelected) {
                // pri editovani docasne prebijeme aj class-y zistene funkciou
                fieldClassName = "bg-green-100";
                parentFieldSelected = true; // tomuto fieldu aj vsetkym jeho subfieldom disablujeme buttony na pridavanie fieldov (aby sme nepremiestnovali field "do seba")
            }

            const styleWidth: React.CSSProperties | undefined = xFieldMeta.width ? {width: xFieldMeta.width} : undefined;
            if (xFieldMeta.type === XFieldType.fieldGroup) {
                // first create inside elements
                const insideElements: JSX.Element[] = this.createInsideElements(xFieldMeta, parentFieldSelected);

                if (createTabPanel) {
                    xFieldElem =
                        <TabPanel key={xFieldMeta.field} header={xFieldMeta.label} style={styleWidth}>
                            {this.state.editMode === true ? this.createLabelElem(xFieldMetaSiblingList!, xFieldMeta, true, parentFieldSelected, undefined, xFieldElemProps) : null}
                            {this.applyChildrenLayout(xFieldMeta, insideElements)}
                        </TabPanel>;
                }
                else if (!xFieldMeta.suppressFieldGroupPanel) {
                    // tooltip (ak mame) zavesime na elementy sprislusnymi id-ckami
                    const xTooltip: XElemIdsAndTooltipElem | undefined = this.createElemIdsAndTooltip(xFieldMeta);
                    // pridame label na zaciatok zoznamu insideElements
                    insideElements.unshift(this.createLabelElem(xFieldMetaSiblingList!, xFieldMeta, true, parentFieldSelected, xTooltip?.labelElemId, xFieldElemProps, `x-fsb-field-group-panel-label ${fieldClassName}`));
                    xFieldElem =
                        <Panel key={xFieldMeta.field} style={styleWidth} header={<div>&nbsp;</div>}
                               className={`x-fsb-field-group-panel ${fieldClassName}`} pt={{header: {className: fieldClassName}, content: {className: fieldClassName}}}>
                            {xTooltip?.tooltipElem}
                            {this.applyChildrenLayout(xFieldMeta, insideElements)}
                        </Panel>;
                }
                else {
                    // v edit mode obsahuje labelElem buttony na editaciu, ked nie sme v edit mode tak je undefined
                    // dame len div namiesto Panel-u
                    xFieldElem =
                        <div key={xFieldMeta.field} className={`x-fsb-field-group-suppressed ${fieldClassName}`} style={styleWidth}>
                            {this.state.editMode === true ? this.createLabelElem(xFieldMetaSiblingList!, xFieldMeta, true, parentFieldSelected, undefined, xFieldElemProps) : null}
                            {this.applyChildrenLayout(xFieldMeta, insideElements)}
                        </div>;
                }
            }
            else if (xFieldMeta.type === XFieldType.checkbox) {
                // tooltip (ak mame) zavesime na elementy sprislusnymi id-ckami
                const xTooltip: XElemIdsAndTooltipElem | undefined = this.createElemIdsAndTooltip(xFieldMeta);
                xFieldElem =
                    <div key={xFieldMeta.field} className={`x-fsb-checkbox ${fieldClassName}`} style={styleWidth}>
                        {xTooltip?.tooltipElem}
                        <label className="x-fsb-checkbox-label" style={xFieldMeta.labelWidth ? {width: xFieldMeta.labelWidth} : undefined}>
                            {this.createLabelElem(xFieldMetaSiblingList!, xFieldMeta, false, parentFieldSelected, xTooltip?.labelElemId, xFieldElemProps)}
                        </label>
                        {/* neslo dat padding (p-2) priamo na Checkbox, lebo ma display: inline-flex a padding ho deformuje */}
                        <div className="x-fsb-checkbox-input-div">
                            <Checkbox id={xTooltip?.inputElemId}
                                      checked={this.getFieldValue(xFieldMeta.field) ?? false} onChange={(e: any) => this.props.onFieldChange(xFieldMeta.field, e.checked ? true : undefined)}
                                      disabled={fieldViewStatus === XViewStatus.ReadOnly}/>
                        </div>
                    </div>
            }
            else if (xFieldMeta.type === XFieldType.inputText) {
                // tooltip (ak mame) zavesime na elementy sprislusnymi id-ckami
                const xTooltip: XElemIdsAndTooltipElem | undefined = this.createElemIdsAndTooltip(xFieldMeta);
                xFieldElem =
                    <div key={xFieldMeta.field} className={`x-fsb-input-text ${fieldClassName}`} style={styleWidth}>
                        {xTooltip?.tooltipElem}
                        <label className="x-fsb-input-text-label" style={xFieldMeta.labelWidth ? {width: xFieldMeta.labelWidth} : undefined}>
                            {this.createLabelElem(xFieldMetaSiblingList!, xFieldMeta, false, parentFieldSelected, xTooltip?.labelElemId, xFieldElemProps)}
                        </label>
                        <InputText className="x-fsb-input-text-input" id={xTooltip?.inputElemId} style={xFieldMeta.inputWidth ? {width: xFieldMeta.inputWidth} : undefined}
                                   value={this.inputTextGetValue(xFieldMeta.field)} onChange={(e: any) => this.inputTextOnValueChange(xFieldMeta.field, e)}
                                   readOnly={fieldViewStatus === XViewStatus.ReadOnly}/>
                    </div>
            }
            else if (xFieldMeta.type === XFieldType.inputDecimal) {
                // zatial dame defaultne hodnoty useGrouping = true a precision = scale + 10
                const useGrouping: boolean = true;
                const scale: number = xFieldMeta.decimalProps?.scale ?? 2; // default 2
                const precision: number = scale + 10; // total number of digits (before + after decimal point (scale))

                // tooltip (ak mame) zavesime na elementy sprislusnymi id-ckami
                const xTooltip: XElemIdsAndTooltipElem | undefined = this.createElemIdsAndTooltip(xFieldMeta);
                xFieldElem =
                    <div key={xFieldMeta.field} className={`x-fsb-input-decimal ${fieldClassName}`} style={styleWidth}>
                        {xTooltip?.tooltipElem}
                        <label className="x-fsb-input-decimal-label" style={xFieldMeta.labelWidth ? {width: xFieldMeta.labelWidth} : undefined}>
                            {this.createLabelElem(xFieldMetaSiblingList!, xFieldMeta, false, parentFieldSelected, xTooltip?.labelElemId, xFieldElemProps)}
                        </label>
                        <XInputDecimalBase className="x-fsb-input-decimal-input" id={xTooltip?.inputElemId} inputStyle={xFieldMeta.inputWidth ? {width: xFieldMeta.inputWidth} : undefined}
                                           value={this.inputDecimalGetValue(xFieldMeta.field)} onChange={(value: number | null) => this.inputDecimalOnValueChange(xFieldMeta.field, value)}
                                           {...XUtilsMetadata.getParamsForInputNumberBase(useGrouping, scale, precision, undefined)}
                                           readOnly={fieldViewStatus === XViewStatus.ReadOnly}/>
                    </div>
            }
        }

        return xFieldElem;
    }

    getFieldViewStatus(xFieldMeta: XFieldMeta): XViewStatus {
        let fieldViewStatus: XViewStatus = XViewStatus.ReadWrite; // default
        if (this.state.editMode === false && this.props.fieldViewStatus) {
            fieldViewStatus = this.props.fieldViewStatus(xFieldMeta);
        }
        return fieldViewStatus;
    }

    createElemIdsAndTooltip(xFieldMeta: XFieldMeta): XElemIdsAndTooltipElem | undefined {
        // neviem ci mozem dat to iste id-cko na label aj na input, tak radsej vytvorim dve rozlicne
        let xElemIdsAndTooltipElem: XElemIdsAndTooltipElem | undefined = undefined;
        if (xFieldMeta.tooltip) {
            const labelElemId: string = `${xFieldMeta.field}_label_id`;
            const inputElemId: string = `${xFieldMeta.field}_input_id`;
            xElemIdsAndTooltipElem = {
                labelElemId: labelElemId,
                inputElemId: inputElemId,
                tooltipElem: <Tooltip target={`#${labelElemId}, #${inputElemId}`} content={xFieldMeta.tooltip}/>
            }
        }
        return xElemIdsAndTooltipElem;
    }

    createInsideElements(xFieldMeta: XFieldMeta, parentFieldSelected: boolean): JSX.Element[] {
        let insideElemList: JSX.Element[] = [];
        if (xFieldMeta.xFieldMetaList) {
            for (const insideXFieldMeta of xFieldMeta.xFieldMetaList) {
                const insideElem: JSX.Element | undefined = this.createXField(xFieldMeta.xFieldMetaList, insideXFieldMeta, xFieldMeta.childrenLayout === XChildrenLayout.tabView, parentFieldSelected);
                if (insideElem) {
                    insideElemList.push(insideElem);
                }
            }
        }
        return insideElemList;
    }

    applyChildrenLayout(xFieldMeta: XFieldMeta, insideElements: JSX.Element[]): JSX.Element[] {
        if (xFieldMeta.childrenLayout === XChildrenLayout.row) {
            insideElements = [<div key="div-children-layout-row" className="x-fsb-children-layout-row">{insideElements}</div>];
        }
        else if (xFieldMeta.childrenLayout === XChildrenLayout.tabView) {
            insideElements =
                [<TabView>
                    {insideElements}
                </TabView>];
        }
        else {
            // XChildrenLayout.column - no change
        }

        return insideElements;
    }

    createLabelElem(xFieldMetaSiblingList: XFieldMeta[], xFieldMeta: XFieldMeta, childAllowed: boolean, disableAddField: boolean, tooltipLabelElemId: string | undefined, xFieldElemProps: XFieldElemProps | undefined, className?: string | undefined): JSX.Element {
        let labelElem: JSX.Element;
        const label: string = xFieldElemProps?.label !== undefined ? xFieldElemProps?.label : xFieldMeta.label;
        if (this.state.editMode === true) {
            labelElem = <div key="div-label-elem" className={className}>
                <div key="div-flex-row" className="flex flex-row flex-nowrap">
                    <XButtonIconSmall key="button-add-as-sibling" icon="pi pi-plus" onClick={() => this.onAddFieldAsSibling(xFieldMetaSiblingList, xFieldMeta)} tooltip="Add sibling field" disabled={disableAddField}/>
                    {childAllowed ? <XButtonIconSmall key="button-add-as-child" icon="pi pi-plus" onClick={() => this.onAddFieldAsChild(xFieldMeta)} tooltip="Add child field" disabled={disableAddField}/> : null}
                    <XButtonIconSmall key="button-edit" icon="pi pi-pencil" onClick={() => this.onEditField(xFieldMetaSiblingList, xFieldMeta)} tooltip="Edit field"/>
                    <XButtonIconSmall key="button-remove" icon="pi pi-trash" onClick={() => this.onRemoveField(xFieldMetaSiblingList, xFieldMeta)} tooltip="Remove field"/>
                    <XButtonIconSmall key="button-move-up" icon="pi pi-chevron-up" onClick={() => this.onMoveFieldUp(xFieldMetaSiblingList, xFieldMeta)} tooltip="Move field up"/>
                    <XButtonIconSmall key="button-move-down" icon="pi pi-chevron-down" onClick={() => this.onMoveFieldDown(xFieldMetaSiblingList, xFieldMeta)} tooltip="Move field down"/>
                    <XButtonIconSmall key="button-select-field" icon="pi pi-cloud-upload" onClick={() => this.onSelectFieldForMove(xFieldMetaSiblingList, xFieldMeta)} tooltip="Select field for move"/>
                </div>
                <div key="div-label" id={tooltipLabelElemId}>{label}</div>
            </div>;
        }
        else {
            labelElem = <div key="div-label-elem" className={className} id={tooltipLabelElemId}>{label}</div>;
        }
        return labelElem;
    }

    render() {
        if (!this.state.xFieldSetMeta) {
            return null; // este nezbehol componentDidMount
        }

        return (
            <div>
                {this.createXFieldRoot(this.state.xFieldSetMeta.xFieldMetaRoot)}
                <XEditFieldDialog dialogOpened={this.state.editFieldDialogOpened} xFieldOperationEnum={this.xFieldOperationEnum!}
                                  xFieldMeta={this.xEditFieldDialogParamXFieldMeta!} getFieldByName={this.getFieldByName} getMaxFieldGroupId={this.getMaxFieldGroupId}
                                  fieldProps={this.props.fieldProps} onHideDialog={this.editFieldDialogOnHide}/>
            </div>
        );
    }
}