import React, {Component} from "react";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XObject} from "@michalrakus/x-react-web-lib/lib/components/XObject";
import {XFieldElemProps, XFieldMeta, XFieldProp, XFieldSetBase, XFieldSetValues} from "./XFieldSetBase";
import {XViewStatus} from "@michalrakus/x-react-web-lib/XUtils";

export interface XFieldSetProps {
    form: XFormBase;
    jsonField: string;
    fieldSetId: string;
    fieldProps?: XFieldProp[];
    fieldViewStatus?: (object: XObject, xFieldMeta: XFieldMeta) => XViewStatus;
    fieldElemProps?: (object: XObject, xFieldMeta: XFieldMeta) => XFieldElemProps | undefined;
    canEdit?: boolean; // user moze field set editovat, len ak canEdit = true
}

export class XFieldSet extends Component<XFieldSetProps> {

    constructor(props: XFieldSetProps) {
        super(props);

        this.onFieldChange = this.onFieldChange.bind(this);
        this.viewStatus = this.viewStatus.bind(this);
        this.fieldElemProps = this.fieldElemProps.bind(this);
    }

    // poznamka - tuto metodu by teoreticky stacilo zavolat raz a ziskany pointer "values" ulozit ako member do komponentu
    // problem je ze ak by sme ju zavolali napr. v this.componentDidMount() tak v tom case este nebol volany form.componentDidMount() a object by bol null,
    // cize by sme sa nedostali k "values"
    getValuesFromForm(): XFieldSetValues {
        let values: XFieldSetValues = {};
        const object: XObject | null = this.props.form.state.object;
        if (object !== null) {
            values = object[this.props.jsonField];
            //  pre istotu dame na null, null je standard
            if (values === undefined) {
                throw `Field ${this.props.jsonField} not found in form (field is undefined)`;
            }
        }
        return values;
    }

    onFieldChange(field: string, value: any) {

        const values: XFieldSetValues = this.getValuesFromForm();
        values[field] = value;

        // zavolame setState na "this.props.form"
        this.props.form.setStateXForm();
    }

    viewStatus(xFieldMeta: XFieldMeta): XViewStatus {
        let viewStatus: XViewStatus = XViewStatus.ReadWrite; // default
        if (this.props.fieldViewStatus) {
            // HEY - object moze byt aj null (ak este nebol volany componentDidMount pre XFormBase), metoda viewStatus to musi zohladnit,
            // zatial dame, ze ak je object === null, vratime XViewStatus.Hidden (po setnuti object dojde k prekresleniu formulara)
            // (jedna sa o podobny problem ako pri XFormComponent.getFilterBase)
            const object: XObject | null = this.props.form.state.object;
            if (object) {
                viewStatus = this.props.fieldViewStatus(object, xFieldMeta);
            }
            else {
                viewStatus = XViewStatus.Hidden;
            }
        }
        return viewStatus;
    }

    fieldElemProps(xFieldMeta: XFieldMeta): XFieldElemProps | undefined {
        let fieldElemProps: XFieldElemProps | undefined = undefined; // default
        if (this.props.fieldElemProps) {
            // HEY - object moze byt aj null (ak este nebol volany componentDidMount pre XFormBase), metoda viewStatus to musi zohladnit,
            // zatial dame, ze ak je object === null, vratime undefined (po setnuti object dojde k prekresleniu formulara)
            // (jedna sa o podobny problem ako pri XFormComponent.getFilterBase)
            const object: XObject | null = this.props.form.state.object;
            if (object) {
                fieldElemProps = this.props.fieldElemProps(object, xFieldMeta);
            }
        }
        return fieldElemProps;
    }

    render() {
        return (
            <XFieldSetBase values={this.getValuesFromForm()} onFieldChange={this.onFieldChange}
                           fieldSetId={this.props.fieldSetId} fieldProps={this.props.fieldProps}
                           fieldViewStatus={this.props.fieldViewStatus ? this.viewStatus : undefined}
                           fieldElemProps={this.props.fieldElemProps ? this.fieldElemProps : undefined}
                           canEdit={this.props.canEdit}/>
        );
    }
}