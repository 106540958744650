import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {EnumEnum, Rola, SluzbaEnum} from "../../common/enums";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {Utils} from "../../Utils";
import {Klient} from "../../model/klient/klient.entity";
import {dateFromModel, stringAsUI, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormRow} from "@michalrakus/x-react-web-lib/XFormRow";
import {Chip} from "primereact/chip";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormScrollable} from "../XFormScrollable";
import {KlientAdresa} from "../noclaharen/KlientAdresa";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {Button} from "primereact/button";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {KlientSluzbaForm} from "./KlientSluzbaForm";
import {XBrowseProps} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {KlientNoclaharenForm} from "../noclaharen/KlientNoclaharenForm";

// da sa otvorit z browsu KlientBrowse ak je tento browse otvoreny cez Administraciu
// dal by sa otvorit na prezeranie/editaciu z autocomplete buttonu vo formulari HladatKlientaMimoSluzbuDialog - zatial to ale nevyuzivame
// vytvoreny na zaklade KlientSluzbaForm (vela veci skopirovanych)
@Form("Klient")
export class KlientForm extends XFormBaseModif {

    constructor(props: XFormProps) {
        super(props);

        this.addField("obec.okres.nazov"); // chceme vidiet obec a okres v autocomplete

        // najoinujeme zaznamy KlientSluzba, aby sme vedeli zobrazit zoznam sluzieb do ktorych klient patri
        this.addField("klientSluzbaList.sluzba.nazov");
    }

    // pomocna metodka
    private getKlient(): Klient | null {
        return this.state.object;
    }

    onChangeDatumNarodeniaIbaRok(e: XFieldChangeEvent<Klient>) {
        if (e.object.datumNarodeniaIbaRok) {
            const datumNarodenia: Date | null = dateFromModel(e.object.datumNarodenia);
            if (datumNarodenia && (datumNarodenia.getMonth() !== 0 || datumNarodenia.getDay() !== 1)) {
                e.object.datumNarodenia = new Date(`${datumNarodenia.getFullYear()}-01-01`);
            }
        }
    }

    // overridneme standardny saveRow
    async saveRow(): Promise<Klient> {
        const reload: boolean = this.props.onSaveOrCancel !== undefined;
        const klient: Klient = await XUtils.fetch('saveRow', {entity: this.getEntity(), object: this.state.object, reload: reload});
        if (reload) {
            // ak robime reload (napr. editujeme objekt cez XAutoComplete), tak nam vracia zlu hodnotu derivovaneho atributu menoPriezviskoPrezyvka
            // je to len taka kozmeticka vec, aby sme videli v XAutoComplete spravnu hodnotu
            // zodpoveda funkcii v databaze
            klient.menoPriezviskoPrezyvka = `${stringAsUI(klient.meno)} ${stringAsUI(klient.priezvisko)} ${stringAsUI(klient.prezyvka)}`.trim();
        }
        return klient;
    }

    createLabel(): string {
        let label: string = "Klient";
        const klient: Klient | null = this.getKlient();
        if (klient) {
            label = Utils.klientCreateLabel(label, klient);
            if (klient.id === undefined) {
                label += " - " + xLocaleOption('newRow');
            }
        }
        return label;
    }

    createChipsSluzby(): JSX.Element[] {
        let elemList: JSX.Element[] = [];
        const klient: Klient | null = this.getKlient();
        if (klient) {
            for (const klientSluzbaZKlienta of klient.klientSluzbaList) {
                // noclaharen sa riesi podla priznaku, nikdy by nemal existovat zaznam KlientSluzba pre noclaharen
                if (klientSluzbaZKlienta.sluzba.kod !== SluzbaEnum.noclaharen) {
                    //elemList.push(<Chip label={klientSluzbaZKlienta.sluzba.nazov} className="m-1"/>);
                    elemList.push(<Button label={klientSluzbaZKlienta.sluzba.nazov} onClick={() => this.onClickChipSluzba(klientSluzbaZKlienta)} className="m-1"/>);
                }
            }
            // noclaharen specialne
            if (klient.noclaharen) {
                //elemList.push(<Chip label="Vincent nocľaháreň" className="m-1"/>);
                elemList.push(<Button label="Vincent nocľaháreň" onClick={() => (this.props as XBrowseProps).openForm!(<KlientNoclaharenForm id={klient.id}/>)} className="m-1"/>);
            }
        }
        return elemList;
    }

    onClickChipSluzba(klientSluzba: KlientSluzba) {
        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu (ak neotvarame form v dialogu (ak ho otvarame cez openForm))
        // @ts-ignore - vypina type checking pre nasledujuci riadok
        (this.props as XBrowseProps).openForm!(<KlientSluzbaForm id={klientSluzba.id} sluzba={klientSluzba.sluzba}/>);
    }

    render() {
        return (
            <div>
                <XFormHeader form={this} label={this.createLabel()} appendNewRow={false}/>
                <XFormScrollable form={this} widthFitContent={true}>
                    <XFormRow>{/* tato najvrchnejsia uroven XFormRow/XFormCol je tu na to aby ohranicila XInputTextarea "poznamky", inac by bol roztiahnuty na cely formular */}
                        <XFormCol>
                            <XFormRow>
                                <XFormCol labelStyle={{width:'10rem'}}>
                                    <XInputText form={this} field="meno" label="Meno" inputStyle={{width:'15rem'}} readOnly={false}/>
                                    <XInputText form={this} field="priezvisko" label="Priezvisko" inputStyle={{width:'15rem'}} readOnly={false}/>
                                    <XInputText form={this} field="prezyvka" label="Prezývka" inputStyle={{width:'15rem'}} readOnly={false}/>
                                    <XInputText form={this} field="rodneCislo" label="Rodné číslo" inputStyle={{width:'7rem'}} placeholder="000000/0000"/>
                                    <div className="x-form-inline-row">
                                        <XInputDate form={this} field="datumNarodenia" label="Dátum narod." labelStyle={{width:'10rem'}} readOnly={false}
                                                    scale={this.getKlient()?.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date}/>
                                        <XCheckbox form={this} field="datumNarodeniaIbaRok" label="iba rok" labelStyle={{width:'5rem'}} readOnly={false} onChange={this.onChangeDatumNarodeniaIbaRok}/>
                                    </div>
                                    <XDropdownEnum form={this} assocField="pohlavie" label="Pohlavie" enumEnumCode={EnumEnum.pohlavie} readOnly={false}/>
                                </XFormCol>
                                <XFormCol>
                                    <KlientAdresa form={this} osetrovna={false} labelStyle={{width:'11rem'}}/>
                                    <XInputDecimal form={this} field="id" label="ID" readOnly={true}/>
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" inputStyle={{width:'12.35rem'}}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <div className="field grid">
                                        <label htmlFor="sluzby" className="col-fixed" style={{width:'10rem'}}>Služby</label>
                                        <div>{this.createChipsSluzby()}</div>
                                    </div>
                                </XFormCol>
                            </XFormRow>
                        </XFormCol>
                    </XFormRow>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
