import React, {useRef, useState} from "react";
import {Menubar} from "primereact/menubar";
import {XFormNavigator3} from "@michalrakus/x-react-web-lib/XFormNavigator3";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XHolder1, XHolder2} from "@michalrakus/x-react-web-lib/XHolders";
import {MenuItem} from "primereact/menuitem";
import {Utils, XsAccessType} from "./Utils";
import {XEnumEnumBrowse} from "./forms/user/XEnumEnumBrowse";
import {SluzbaDropdown} from "./forms/user/SluzbaDropdown";
import {XUserDePaulBrowse} from "./forms/user/XUserDePaulBrowse";
import {SluzbaBrowse} from "./forms/user/SluzbaBrowse";
import {HromEvidForm} from "./forms/klient/HromEvidForm";
import {ZapisOsetrovnaBrowse} from "./forms/zapisy/ZapisOsetrovnaBrowse";
import {Param, Rola, SluzbaEnum, TypCinnosti} from "./common/enums";
import {MiestoMoznehoKontaktuBrowse} from "./forms/zapisy/MiestoMoznehoKontaktuBrowse";
import {VykazStreetworkBrowse} from "./forms/zapisy/VykazStreetworkBrowse";
import {Sluzba} from "./model/user/sluzba.entity";
import {SluzbaRolaPravaBrowse} from "./forms/user/SluzbaRolaPravaBrowse";
import {ZapisBrowse} from "./forms/zapisy/ZapisBrowse";
import {CenovaKategoriaBrowse} from "./forms/klient/CenovaKategoriaBrowse";
import {ZmluvaBrowse} from "./forms/klient/ZmluvaBrowse";
import {XEnum} from "./model/user/x-enum.entity";
import {UbytovanieBrowse} from "./forms/noclaharen/UbytovanieBrowse";
import {KlientNoclaharenBrowse} from "./forms/noclaharen/KlientNoclaharenBrowse";
import {MiestnostBrowse} from "./forms/noclaharen/MiestnostBrowse";
import {IdList, XDateFromToRequest, XYearRequest} from "./common/utils-api";
import {XDateFromToDialog, XDateFromToDialogState} from "./forms/XDateFromToDialog";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XYearDialog, XYearDialogState} from "./forms/XYearDialog";
import {ReportKlientiPodlaMiestnostiDialog} from "./forms/noclaharen/ReportKlientiPodlaMiestnostiDialog";
import {XParamNoclaharenBrowse} from "./forms/noclaharen/XParamNoclaharenBrowse";
import {XUserDePaul} from "./model/user/x-user-de-paul.entity";
import {XUserSluzbaRola} from "./model/user/x-user-sluzba-rola.entity";
import {UbytovanieForm} from "./forms/noclaharen/UbytovanieForm";
import {Toast} from "primereact/toast";
import {KlientSluzbaBrowse} from "./forms/klient/KlientSluzbaBrowse";
import {XsGroupByFieldBrowse} from "./forms/statistics/XsGroupByFieldBrowse";
import {XsStatFieldBrowse} from "./forms/statistics/XsStatFieldBrowse";
import {XsTableBrowse} from "./forms/statistics/XsTableBrowse";
import {XsIntervalSetBrowse} from "./forms/statistics/XsIntervalSetBrowse";
import {XrReportBrowse} from "./forms/reports/XrReportBrowse";
import {ZapisForm} from "./forms/zapisy/ZapisForm";
import {StatBrowse} from "./forms/ciselniky/StatBrowse";
import {ObecBrowse} from "./forms/ciselniky/ObecBrowse";
import {KlientBrowse} from "./forms/klient/KlientBrowse";

// TODO - v buducnosti presunut do XReactWebLib
export const XMenu = (props: {defaultFormElement?: any; logout: () => void;}) => {

    const [rootFormElement, setRootFormElement] = useState<any>(props.defaultFormElement);
    const [renderHolder1, setRenderHolder1] = useState<boolean>(true);

    const [xDateFromToDialogState, setXDateFromToDialogState] = useState<XDateFromToDialogState>({opened: false});
    const [xYearDialogState, setXYearDialogState] = useState<XYearDialogState>({opened: false});
    const [reportKlientiPodlaMiestnostiDialogOpened, setReportKlientiPodlaMiestnostiDialogOpened] = useState<boolean>(false);

    const toast = useRef<Toast>(null);

    const mamePozadovanuSluzbu = (form: any, typCinnosti?: TypCinnosti): boolean => {
        // niektore formulare, napr. KlientSluzbaBrowse, ZapisBrowse su pre vsetky sluzby (vtedy zostane premenna "sluzby" undefined)
        let sluzby: SluzbaEnum[] | undefined = undefined;
        switch (form) {
            case ZapisOsetrovnaBrowse: sluzby = [SluzbaEnum.osetrovnaNDC]; break;
            case VykazStreetworkBrowse: sluzby = [SluzbaEnum.streetwork]; break;
            case MiestoMoznehoKontaktuBrowse:
                sluzby = [SluzbaEnum.streetwork, SluzbaEnum.osetrovnaNDC];
                break;
            case HromEvidForm:
                // sprchovanie, vymena postelnej bielizne, pranie, ubytovanie - pre vsetky utulky
                sluzby = [SluzbaEnum.utulokLujza, SluzbaEnum.utulokVincent, SluzbaEnum.utulokZOS];
                break;
            case ZmluvaBrowse:
            case CenovaKategoriaBrowse:
                sluzby = [SluzbaEnum.utulokLujza, SluzbaEnum.utulokVincent, SluzbaEnum.utulokZOS/*, SluzbaEnum.noclaharen*/];
                break;
            case KlientNoclaharenBrowse:
            case UbytovanieBrowse:
            case UbytovanieForm:
            case MiestnostBrowse:
            case XParamNoclaharenBrowse:
                sluzby = [SluzbaEnum.noclaharen];
                break;
        }

        let mamePozadovanuSluzbu: boolean = false; // default
        if (sluzby === undefined) {
            // mame nejaky browse pre vsetkych (napr. Klienti, Zapisy, Statistiky, ...)
            const klientSluzbaAleboZapis: boolean = (form === KlientSluzbaBrowse || form === ZapisForm || form === ZapisBrowse);
            if (klientSluzbaAleboZapis) {
                if (!Utils.getCurrentSluzba()) {
                    // pre pracu s klient/zapis musi zadat sluzbu
                    mamePozadovanuSluzbu = false;
                }
                else if (Utils.getCurrentSluzba()?.kod === SluzbaEnum.noclaharen) {
                    // browse klientov zablokujeme pre noclaharen - ta ma svoj vlastny browse klientov
                    // podobne zapisy zablokujeme pre noclaharen - na vytvaranie zapisov (soc. poradenstva) existuje ina sluzba
                    mamePozadovanuSluzbu = false;
                }
                else {
                    // ostatne sluzby pouzivaju zapisy
                    mamePozadovanuSluzbu = true;
                }
            }
            else {
                // vseobecny browse nezavisly od sluzby (sluzba ani nemusi byt vyplnena), napr. Statistiky
                mamePozadovanuSluzbu = true;
            }
        }
        else {
            const sluzbaKod: string | undefined = Utils.getCurrentSluzba()?.kod;
            if (sluzbaKod) {
                mamePozadovanuSluzbu = sluzby.includes(sluzbaKod as SluzbaEnum);
            }
        }
        return mamePozadovanuSluzbu;
    }

    // helper
    const noclaharenReportDisabled = (): boolean => {
        return !Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci, Rola.socialnyPracovnik);
    }

    const items: MenuItem[] = [
        {
            label:'Aplikácia',
            disabled: false,
            items:[
                {label:'Klienti', command: () => {openForm(<KlientSluzbaBrowse/>);}, disabled: !mamePozadovanuSluzbu(KlientSluzbaBrowse)},
                {label:'Zmluvy', command: () => {openForm(<ZmluvaBrowse/>);}, disabled: !mamePozadovanuSluzbu(ZmluvaBrowse)},
                {label:'Pridať zápis opakovane', command: () => {openForm(<ZapisForm onSaveOrCancel={(object: any | null, objectChange: OperationType) => onSaveOrCancelZapisForm(object, objectChange, !renderHolder1)}/>);}, disabled: !mamePozadovanuSluzbu(ZapisForm)}, // !renderHolder1 - po zavolani openForm bude mat renderHolder1 opacnu hodnotu - preto negacia !
                {label:'Zápisy', command: () => {openForm(<ZapisBrowse/>);}, disabled: !mamePozadovanuSluzbu(ZapisBrowse)},
                //{label:'Zápisy - ošetrovňa', command: () => {openForm(<ZapisOsetrovnaBrowse/>);}, disabled: !mamePozadovanuSluzbu(ZapisOsetrovnaBrowse)},
                {label:'Výkazy - streetwork', command: () => {openForm(<VykazStreetworkBrowse/>);}, disabled: !mamePozadovanuSluzbu(VykazStreetworkBrowse)},
                {label:'Miesta možného kontaktu', command: () => {openForm(<MiestoMoznehoKontaktuBrowse/>);}, disabled: !mamePozadovanuSluzbu(MiestoMoznehoKontaktuBrowse)}
            ]
        },
        {
            label:'Hromadná evid.',
            disabled: false,
            items:[
                // TODO - ked budu enumy cache-ovane, tak pouzit x-enum
                {label:'Sprchovanie', command: () => {openForm(<HromEvidForm typCinnosti={{id: 61, code: "sprchovanie", name: "sprchovanie"} as XEnum}/>);}, disabled: !mamePozadovanuSluzbu(HromEvidForm, TypCinnosti.sprchovanie)},
                {label:'Výmena posteľnej bielizne', command: () => {openForm(<HromEvidForm typCinnosti={{id: 62, code: "vymenaPostelnejBielizne", name: "výmena posteľnej bielizne"} as XEnum}/>);}, disabled: !mamePozadovanuSluzbu(HromEvidForm, TypCinnosti.vymenaPostelnejBielizne)},
                {label:'Pranie osobných vecí', command: () => {openForm(<HromEvidForm typCinnosti={{id: 63, code: "pranieOsobnychVeci", name: "pranie osobných vecí"} as XEnum}/>);}, disabled: !mamePozadovanuSluzbu(HromEvidForm, TypCinnosti.pranieOsobnychVeci)},
                {label:'Ubytovanie', command: () => {openForm(<HromEvidForm typCinnosti={{id: 64, code: "ubytovanie", name: "ubytovanie"} as XEnum}/>);}, disabled: !mamePozadovanuSluzbu(HromEvidForm, TypCinnosti.ubytovanie)}
            ]
        },
        {
            label:'Nocľaháreň',
            disabled: !(Utils.getCurrentSluzba()?.kod === SluzbaEnum.noclaharen),
            items:[
                {label:'Klienti', command: () => {openForm(<KlientNoclaharenBrowse/>);}, disabled: !mamePozadovanuSluzbu(KlientNoclaharenBrowse)},
                {label:'Pridať ubytovanie opakovane', command: () => {openForm(<UbytovanieForm onSaveOrCancel={(object: any | null, objectChange: OperationType) => onSaveOrCancelUbytovanieForm(object, objectChange, !renderHolder1)}/>);}, disabled: !mamePozadovanuSluzbu(UbytovanieForm)}, // !renderHolder1 - po zavolani openForm bude mat renderHolder1 opacnu hodnotu - preto negacia !
                {label:'Ubytovania', command: () => {openForm(<UbytovanieBrowse/>);}, disabled: !mamePozadovanuSluzbu(UbytovanieBrowse)},
                {label:'Ubytovania dnes', command: () => {openForm(<UbytovanieBrowse dnes={true}/>);}, disabled: !mamePozadovanuSluzbu(UbytovanieBrowse)},
                {
                    label:'Reporty',
                    disabled: !(Utils.getCurrentSluzba()?.kod === SluzbaEnum.noclaharen),
                    items: [
                        {label:'Noví klienti', command: () => {openReportObdobieOdDo('novi-klienti');}, disabled: noclaharenReportDisabled()},
                        {label:'Štatistika klientov za rok', command: () => {openReportStatistikaKlientovZaRok();}, disabled: noclaharenReportDisabled()},
                        {label:'Štatistika klientov za obdobie', command: () => {openReportObdobieOdDo('statistika-klientov-za-obdobie');}, disabled: noclaharenReportDisabled()},
                        {label:'Matica klient - deň', command: () => {openReportObdobieOdDo('matica-klient-den');}, disabled: noclaharenReportDisabled()},
                        {label:'Počet klientov za deň', command: () => {openReportObdobieOdDo('pocet-klientov-za-den');}, disabled: noclaharenReportDisabled()},
                        {label:'Platby klientov za deň', command: () => {openReportObdobieOdDo('platby-klientov-za-den');}, disabled: noclaharenReportDisabled()},
                        {label:'Klienti podľa miestnosti', command: () => {openReportKlientiPodlaMiestnosti();}}, // tento report je pristupny aj pracovnikom
                        {label:'Prerušenia služby', command: () => {openReportObdobieOdDo('noclaharen-prerusenia');}} // tento report je pristupny aj pracovnikom
                    ]
                },
                {
                    label:'Administrácia',
                    disabled: !Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci),
                    items: [
                        {label:'Miestnosti', command: () => {openForm(<MiestnostBrowse/>);}},
                        {label:'Parametre', command: () => {openForm(<XParamNoclaharenBrowse/>);}},
                        {label:'Vrecia na likvidáciu', command: () => {vreciaNaLikvidaciu();}},
                    ]
                }
            ]
        },
        {
            label:'Číselníky',
            disabled: false,
            items:[
                {label:'Cenové kategórie', command: () => {openForm(<CenovaKategoriaBrowse/>);}, disabled: !mamePozadovanuSluzbu(CenovaKategoriaBrowse)},
                {label:'Štáty', command: () => {openForm(<StatBrowse/>);}, disabled: !mamePozadovanuSluzbu(StatBrowse)},
                {label:'Obce/mestá', command: () => {openForm(<ObecBrowse/>);}, disabled: !mamePozadovanuSluzbu(ObecBrowse)}
            ]
        },
        {
            label:'Štatistiky',
            disabled: !Utils.xsUserHasAccess(XsAccessType.runOnly),
            items:[
                {label:'Interval sets', command: () => {openForm(<XsIntervalSetBrowse/>);}},
                {label:'Group by fields', command: () => {openForm(<XsGroupByFieldBrowse/>);}},
                {label:'Stat fields', command: () => {openForm(<XsStatFieldBrowse/>);}},
                {label:'Stat tables', command: () => {openForm(<XsTableBrowse/>);}},
                {label:'Reports', command: () => {openForm(<XrReportBrowse/>);}}
            ]
        },
        {
            label:'Administrácia',
            disabled: !Utils.isUserAdmin(),
            items:[
                {label:'Používatelia', command: () => {openForm(<XUserDePaulBrowse/>);}},
                //{label:'Change password', command: () => {openForm(<XChangePasswordForm setXToken={props.setXToken}/>);}}
                {label:'Enums', command: () => {openForm(<XEnumEnumBrowse/>);}},
                {label:'Služby', command: () => {openForm(<SluzbaBrowse/>);}},
                {label:'Práva', command: () => {openForm(<SluzbaRolaPravaBrowse/>);}},
                {label:'Klienti - všetky služby', command: () => {openForm(<KlientBrowse label="Klienti - všetky služby"/>);}}
            ]
        },
        {
            label:'Odhlásenie',
            icon:'pi pi-fw pi-power-off',
            command: props.logout
        }
    ];

    const openForm = (newFormElement: any) => {
        setRootFormElement(newFormElement);
        // we want refresh the whole element tree (call constructors of class components, call componentDidMount methods to refresh data, ...)
        // change of Holder1 to Holder2 and back will cause mounting of new component tree
        // without this, changing from <XBrowse entity="Brand"/> to <XBrowse entity="Car"/> will not work (component tree will not change)
        setRenderHolder1(!renderHolder1);
    }

    const onSaveOrCancelUbytovanieForm = (object: any | null, objectChange: OperationType, renderHolder1: boolean) => {
        // dame aj pre cancel novy (cisty) formular
        //if (objectChange === OperationType.Insert) {
            // bol save, otvorime formular pre novy insert
            // hodnotu renderHolder1 si odovzdavame cez parameter, lebo ked sme ju citali zo state variable renderHolder1, tak bola vzdy ta ista (ked odovzdavame funkciu, zachovava sa kontext)
            const renderHolder1New: boolean = !renderHolder1;
            setRootFormElement(<UbytovanieForm onSaveOrCancel={(object: any | null, objectChange: OperationType) => onSaveOrCancelUbytovanieForm(object, objectChange, renderHolder1New)}/>);
            setRenderHolder1(renderHolder1New);
            if (objectChange === OperationType.Insert) {
                toast.current?.show({severity: 'info', summary: 'Info', detail: 'Ubytovanie bolo zapísané', life: 3000});
            }
        //}
        //else {
            // bol cancel (malo by platit objectChange === OperationType.None), zobrazime prazdny screen
        //    setRootFormElement(null); // ziaden form sa nezobrazi (treba ist do menu a nieco zvolit)
        //}
    }

    const onSaveOrCancelZapisForm = (object: any | null, objectChange: OperationType, renderHolder1: boolean) => {
        // hodnotu renderHolder1 si odovzdavame cez parameter, lebo ked sme ju citali zo state variable renderHolder1, tak bola vzdy ta ista (ked odovzdavame funkciu, zachovava sa kontext)
        const renderHolder1New: boolean = !renderHolder1;
        setRootFormElement(<ZapisForm onSaveOrCancel={(object: any | null, objectChange: OperationType) => onSaveOrCancelZapisForm(object, objectChange, renderHolder1New)}/>);
        setRenderHolder1(renderHolder1New);
        if (objectChange === OperationType.Insert) {
            toast.current?.show({severity: 'info', summary: 'Info', detail: 'Zápis bol zapísaný', life: 3000});
        }
    }

    const vreciaNaLikvidaciu = async () => {
        const exspiraciaVriec: number = await Utils.getXParamValueAsInt(Param.n_exspiraciaVriec);
        const datumUvolnitNepouziteOd: Date = XUtilsCommon.dateAddDays(Utils.todayNoclaharen(), -exspiraciaVriec)!;

        let idKlientList: IdList;
        try {
            idKlientList = await XUtils.fetchOne('vrecia-na-likvidaciu-najdi-klient-ids', {datumUvolnitNepouziteOd: datumUvolnitNepouziteOd});
        }
        catch (e) {
            XUtils.showErrorMessage('Nepodarilo sa zistiť id klientov s vrecami na likvidáciu.', e);
            return;
        }

        if (idKlientList.idList.length === 0) {
            alert("Nenašli sa žiadne vrecia na likvidáciu.");
        }
        else {
            if (!await XUtils.openExcelReport('vrecia-na-likvidaciu-vytvor-report', idKlientList, 'vrecia-na-likvidaciu')) {
                return;
            }

            if (window.confirm("Uvoľniť nájdené čísla vriec?")) {
                try {
                    await XUtils.fetch('vrecia-na-likvidaciu-uvolni', idKlientList);
                }
                catch (e) {
                    XUtils.showErrorMessage('Nepodarilo sa uvoľniť nájdené čísla vriec.', e);
                    return;
                }
                alert("Nájdené čísla vriec boli úspešne uvoľnené.");
            }
        }
    }

    const openReportObdobieOdDo = (apiPath: string) => {
        const datumDo: Date = Utils.todayNoclaharen();
        let datumOd: Date = XUtilsCommon.dateAddMonths(datumDo, -1)!;
        datumOd = XUtilsCommon.dateAddDays(datumOd, 1)!;
        setXDateFromToDialogState({
            opened: true,
            dateFrom: datumOd,
            dateTo: datumDo,
            onHideDialog: (ok: boolean, dateFrom: Date | null, dateTo: Date | null) => openReportObdobieOdDoOnHideDialog(ok, dateFrom, dateTo, apiPath)
        });
    }

    const openReportObdobieOdDoOnHideDialog = (ok: boolean, dateFrom: Date | null, dateTo: Date | null, apiPath: string) => {
        if (ok) {
            const xDateFromToRequest: XDateFromToRequest = {dateFrom: dateFrom, dateTo: dateTo};
            XUtils.openExcelReport(apiPath, xDateFromToRequest);
        }
        setXDateFromToDialogState({opened: false});
    }

    const openReportStatistikaKlientovZaRok = () => {
        const year: number = Utils.todayNoclaharen().getFullYear();
        setXYearDialogState({
            opened: true,
            yearFrom: 2014, // najnizsi datum v tabulke ubytovanie je v roku 2014
            yearTo: year,
            yearInit: year,
            onHideDialog: openReportStatistikaKlientovZaRokOnHideDialog
        });
    }

    const openReportStatistikaKlientovZaRokOnHideDialog = (ok: boolean, year: number | undefined) => {
        if (ok) {
            const xYearRequest: XYearRequest = {year: year!};
            XUtils.openExcelReport('statistika-klientov-za-rok', xYearRequest);
        }
        setXYearDialogState({opened: false});
    }

    const openReportKlientiPodlaMiestnosti = () => {
        setReportKlientiPodlaMiestnostiDialogOpened(true);
    }

    const onChangeSluzba = (sluzba: Sluzba) => {
        // rootFormElement.type je napr. ZapisOsetrovnaBrowse (t.j. funguje podmienka rootFormElement.type === ZapisOsetrovnaBrowse)
        // TODO - vydolovat a pridat props.typCinnosti koli HromEvidForm
        if (rootFormElement && !mamePozadovanuSluzbu(rootFormElement.type)) {
            setRootFormElement(null); // ziaden form sa nezobrazi (treba ist do menu a nieco zvolit)
        }
        // otvorime browse, ktory mame ulozeny v rootFormElement (posledne otvoreny browse)
        setRenderHolder1(!renderHolder1);
    }

    const zistiRole = (): string => {
        let role: string = "";
        const user: XUserDePaul = Utils.getXUserDePaul();
        if (user.admin) {
            role = "Admin";
        }
        const currentSluzba: Sluzba | undefined = Utils.getCurrentSluzba();
        if (currentSluzba) {
            // mame vybratu sluzbu v comboboxe v menu
            const sluzbaRolaList: XUserSluzbaRola[] = user.xUserSluzbaRolaList.filter((xUserSluzbaRola: XUserSluzbaRola) => xUserSluzbaRola.sluzbaRola.sluzba.id === currentSluzba.id);
            const rolaList: string[] = sluzbaRolaList.map((xUserSluzbaRola: XUserSluzbaRola) => xUserSluzbaRola.sluzbaRola.rola.name);
            if (rolaList.length > 0) {
                const roleStr: string = rolaList.join(', ');
                if (role !== "") {
                    role += ", ";
                }
                role += roleStr;
            }
            if (role === "") {
                role = "žiadna"; // pre danu sluzbu nema priradenu ziadnu rolu (asi ani nemoze nastat)
            }
        }
        return role;
    }

    const backendUrl: string = XUtils.getXBackendUrl();
    const testEnv: boolean = backendUrl.indexOf('test') !== -1; // ak mame app-test.depaul.sk

    /* dropdown ide nalavo, backend + user ide napravo (pomocou marginLeft: 'auto') - este boli potrebne zmeny v App.css */
    const end: any = (
        <div className="flex" style={{width: '100%'}}>
            <div className="m-3"/>
            <SluzbaDropdown onChangeSluzba={onChangeSluzba}/>
            <div className="flex align-content-center" style={{marginLeft: 'auto'}}>
                {testEnv ? <div style={{padding: '0.5rem', fontWeight: 'bold'}}>TEST</div> : null}
                {/* <div style={{padding: '0.5rem'}}>Backend: {XUtils.getXBackendUrl()}</div> */}
                <div style={{padding: '0.5rem'}}>Používateľ: {/*XUtils.getUsername()*/XUtils.getXToken()?.xUser?.name}</div>
                <div style={{padding: '0.5rem'}}>Rola: {zistiRole()}</div>
            </div>
        </div>
    );

    // little workaround - see remark in function openForm
    let holderWithNavigator;
    if (renderHolder1) {
        holderWithNavigator = <XHolder1 element={<XFormNavigator3 rootFormElement={rootFormElement}/>}/>;
    }
    else {
        holderWithNavigator = <XHolder2 element={<XFormNavigator3 rootFormElement={rootFormElement}/>}/>;
    }

    return (
        <div>
            <Toast ref={toast} position="top-center"/>
            <Menubar id="menuId" model={items} end={end} className="mb-1" pt={testEnv ? {root: {style: {backgroundColor: 'lightskyblue'}}} : undefined}/>
            {holderWithNavigator}
            <XDateFromToDialog dialogState={xDateFromToDialogState}/>
            <XYearDialog dialogState={xYearDialogState}/>
            <ReportKlientiPodlaMiestnostiDialog dialogOpened={reportKlientiPodlaMiestnostiDialogOpened} onHideDialog={() => setReportKlientiPodlaMiestnostiDialogOpened(false)}/>
        </div>
    );
}
